<template>
  <b-card class="text-center">
    <b-avatar
        :variant="`light-${color}`"
        size="60"
        class="bordered mb-50"
    >
    <feather-icon
        size="24"
        :icon="icon"
    />
    </b-avatar>
    <div class="truncate">
      <h5 class="my-50 font-weight-bolder">
        {{ statistic }}
    </h5>
      <span class="text-secondary">{{ statisticTitle }}</span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
<style>
.bordered {
    border: 1px solid rgba(90, 90, 255, 0.60) !important;
    background-color: rgba(90, 90, 255, 0.10) !important;
}
.bordered:hover {
    border: 1px solid rgba(90, 90, 255, 0.80) !important;
    background-color: rgba(90, 90, 255, 0.12) !important;
}
</style>
