<template>
    <section id="dashboard-analytics">
        <b-row>
            <b-col md="4">
                <b-link :to="{ name: 'em-surveys' }">
                    <statistic-card-vertical
                        color="primary"
                        icon="ClipboardIcon"
                        :statistic="i18nT(`Surveys`)"
                        :statistic-title="i18nT(`Your surveys`)"
                    />
                </b-link>
            </b-col>
            <b-col md="4">
                <b-link :to="{
              name: 'tasks',
              params: { software: currentSoftware },
            }">
                    <statistic-card-vertical
                        color="primary"
                        icon="CheckCircleIcon"
                        :statistic="i18nT(`Tasks`)"
                        :statistic-title="i18nT(`Your tasks`)"
                    />
                </b-link>
            </b-col>
            <b-col md="4">
                <b-link :to="{
              name: 'inbox-all',
              query: {
                      newMsg: 1
                  }
            }">
                    <statistic-card-vertical
                        color="primary"
                        icon="MailIcon"
                        :statistic="i18nT(`Message`)"
                        :statistic-title="i18nT(`Send new message`)"
                    />
                </b-link>
            </b-col>
        </b-row>

        <b-row class="match-height">
            <b-col lg="4">
                <b-card no-body>
                    <b-card-header>
                        <b-card-title class="ml-25">
                            {{ surveys.count }} {{ i18nT(`Surveys`) }}
                        </b-card-title>
                    </b-card-header>

                    <!-- timeline -->
                    <b-card-body>
                        <b-media
                            no-body
                            v-for="survey in surveys.items"
                            :key="survey.Id"
                            class="media pb-50 pt-50 border-bottom"
                        >
                            <b-media-body class="my-auto">
                                <b-link :to="{
                                      name: 'em-view-survey',
                                      params: {id: survey.Id}
                                      }">
                                    <h6 class="mb-0 text-primary">
                                        {{ survey.Label }}
                                    </h6>
                                </b-link>
                                <p
                                    class="mb-0"
                                    v-html="survey.Description"
                                >
                                </p>
                            </b-media-body>
                        </b-media>
                        <b-media
                            style="text-align: center;"
                            v-if="surveys.count === 0"
                        >
                              <span
                                  class="b-avatar badge-light-secondary rounded mb-2 mt-4"
                                  style="width: 70px; height: 70px;"
                              >
                                <feather-icon
                                    icon="ClipboardIcon"
                                    size="36"
                                />
                              </span>
                            <h5>{{ i18nT(`Nothing new`) }}</h5>
                            <p class="text-muted"> {{ i18nT(`There are no surveys.`) }}</p>
                        </b-media>
                        <!--/ timeline -->
                    </b-card-body>
                    <b-link
                        :to="{name: 'em-surveys'}"
                        style="padding: 10px;"
                    >
                        <b-button
                            variant="outline-secondary"
                            style="width: 100%"
                        >
                            {{ i18nT(`See all`) }}
                        </b-button>
                    </b-link>
                </b-card>
            </b-col>

            <b-col lg="4">
                <b-card no-body>
                    <b-card-header>
                        <b-card-title class="ml-25">
                            {{ tasks.count }} {{ i18nT(`Tasks`) }}
                        </b-card-title>
                    </b-card-header>

                    <!-- timeline -->
                    <b-card-body>
                        <b-media
                            no-body
                            v-for="task in tasks.items"
                            :key="task.Id"
                            class="media pb-50 pt-50 border-bottom"
                        >
                            <b-media-body class="my-auto">
                                <h6 class="mb-0 text-primary">
                                    {{ task.Label }}
                                </h6>
                                <div class="mb-0" v-html="task.Description"></div>
                            </b-media-body>
                        </b-media>
                        <b-media
                            style="text-align: center;"
                            v-if="tasks.count === 0"
                        >
                              <span
                                  class="b-avatar badge-light-secondary rounded mb-2 mt-4"
                                  style="width: 70px; height: 70px;"
                              >
                                <feather-icon
                                    icon="CheckCircleIcon"
                                    size="36"
                                />
                              </span>
                            <h5>{{ i18nT(`Nothing new`) }}</h5>
                            <p class="text-muted"> {{ i18nT(`There are no tasks.`) }}</p>
                        </b-media>
                        <!--/ timeline -->
                    </b-card-body>
                    <b-link
                        :to="{
              name: 'tasks',
              params: { software: currentSoftware },
            }"
                        style="padding: 10px;"
                    >
                        <b-button
                            variant="outline-secondary"
                            style="width: 100%"
                        >
                            {{ i18nT(`See all`) }}
                        </b-button>
                    </b-link>
                </b-card>
            </b-col>

            <b-col lg="4">
                <analytics-messages
                    :data="messages"
                />
            </b-col>
        </b-row>
    </section>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BLink,
    BButton,
    BBadge
} from 'bootstrap-vue'

import {kFormatter} from '@core/utils/filter'
import AnalyticsCongratulation from './analytics/AnalyticsCongratulation.vue'
import AnalyticsMessages from './analytics/AnalyticsMessages.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import useAuth from '@/auth/useAuth'
import _ from 'lodash'

export default {
    components: {
        BRow,
        BCol,
        AnalyticsCongratulation,
        AnalyticsMessages,
        StatisticCardVertical,
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BMedia,
        BMediaBody,
        BLink,
        BButton,
        BBadge
    },
    data() {
        return {
            data: {},
            surveys: {
                items: [],
                count: 0
            },
            tasks: {
                items: [],
                count: 0
            },
            messages: {
                messages: [],
                count: 0
            }
        }
    },
    computed: {
        currentSoftware() {
            return useAuth.getCurrentSoftware()
        }
    },
    created() {
        this.$http.get(`main/emDashboard`).then(({data}) => {
            this.data = data.data
            this.surveys = {
                items: data.data.surveys,
                count: data.data.surveys_count
            }
            this.tasks = {
                items: data.data.tasks,
                count: data.data.tasks_count
            }
            this.messages = {
                messages: data.data.messages,
                count: data.data.messages_count
            }

        })
    },
    methods: {
        kFormatter,
        truncate: function (data, num) {
            const reqdString = data
                .split(' ')
                .slice(0, num)
                .join(' ')
            return data.length > reqdString.length ? reqdString + '...' : reqdString
        }
    }
}
</script>
