<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="ml-25 d-flex justify-content-between w-100">
        <span>{{data.count}} {{i18nT(`New messages`)}}</span>
        <!--<b-link
          :to="{
            name: 'inbox-new',
            params: { software: software },
          }"
        >{{i18nT(`Compose a message`)}}</b-link>-->
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <b-media
        no-body
        v-for="message in data.messages"
        :key="message.Id"
        class="pb-50 pt-50 border-bottom"
      >
        <b-media-aside>
          <b-avatar
            :src="message.RecipientObj.ImageUrl"
            :text="(message.RecipientObj.FirstName ? message.RecipientObj.FirstName.charAt(0) : '') + (message.RecipientObj.LastName ? message.RecipientObj.LastName.charAt(0) : '')"
            class="badge-light-primary"
            size="38"
          />
        </b-media-aside>
        <b-media-body class="my-auto overflow-hidden">
          <div class="float-right text-muted">
            {{ message.UpdatedAt | date }}
          </div>
          <b-link
            :to="{
              name: 'inbox-message',
              params: { msgid: message.Id,
              software: software },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            <span class="bullet bullet-sm bullet-success"></span>
            {{ message.Label }}
          </b-link>
          <p class="mb-0" v-html="truncate(message.Message, 30)"></p>
        </b-media-body>
      </b-media>

      <b-media
        style="text-align: center;"
        v-if="data.count === 0"
      >
        <span
          class="b-avatar badge-light-secondary rounded mb-2 mt-4"
          style="width: 70px; height: 70px;"
        >
          <feather-icon
            icon="MailIcon"
            size="36"
          />
        </span>
        <h5>{{i18nT(`Nothing new`)}}</h5>
        <p class="text-muted">{{i18nT(`There are no new messages.`)}}</p>
      </b-media>
      <!--/ timeline -->
    </b-card-body>
    <a
      :href="mailboxLink"
      style="padding: 8px;"
    >
      <b-button
        variant="outline-secondary"
        style="width: 100%;"
      >
        {{i18nT(`See all`)}}
      </b-button>
    </a>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BLink,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BBadge,
  BButton
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useAuth from '@/auth/useAuth'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    // BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    // AppTimeline,
    // AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    // BAvatarGroup,
    BButton,
    BLink
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  filters: {
    truncate: function(data, num) {
      const reqdString = data
        .split(' ')
        .slice(0, num)
        .join(' ')
      return reqdString
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        count: 0,
        messages: []
      })
    },
  },
  methods: {
    truncate: function(data, num) {
      const reqdString = data
        .split(' ')
        .slice(0, num)
        .join(' ')
      return data.length > reqdString.length ? reqdString + '...' : reqdString
    }
  },
  created() {
    this.software = useAuth.getCurrentSoftware()
  },
  data() {
      return {
          mailboxLink: '/messages/inbox'
      }
  }
}
</script>
